<template>
  <div>
    <div class="bodyer">
      <div class="main-context">
        <div class="bodyer-box">
          <div class="contact">
            <div class="contact-title">
              公司介绍
              <div>Company Introduction</div>
            </div>
            <div class="contact-content">
              <div class="contact-img">
                <img src="../assets/images/logox@2x.png">
              </div>
              <div class="contact-info">
                <p>
                  江苏央苗供应链管理有限公司（简称“央苗”）成立于2020年，是专注政府、企业、高校、金融等客户的营销用品、员工福利一站式解决方案的管理公司。
                </p>
                <p>
                  我们是政府、企业、高校、金融等礼品类一站式解决方案的提供商。目前“礼享购”平台拥有礼品十二大类10000+
                  SKU，先后与携程、万达影院、阿里巴巴、大润发、中国旅、一想文化、中粮、鲁花、金龙鱼、三只松鼠、紫罗兰、希尔顿花园、无印良品、罗莱、南极人、华为、AO史密斯、松下、美的、格力、亚都、夏普、虎牌、膳魔师、格兰仕、飞利浦、双立人、德龙、宝洁、联合利华、阿迪达斯、得力、真彩等千余家国内外知名品牌合作，真正做到了“一站式”礼品采购服务。
                </p>
                <p>
                  央苗的核心价值观是“快、好、优、省”，大力开发终端市场，为客户提供优质的服务，本着为客户减少中间环节，真正做到瘦身提效，保证客户在降低采购成本的同时提高工作效率。
                </p>
                <p>
                  我们坚信，只要以市场为导向，以创新为动力，以品质为保障，以发展求壮大，我们一定会像“秧苗”一样茁壮成长。
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="bodyer-box">
          <div class="contact">
            <div class="contact-title">
              企业文化
              <div>Corporate Culture</div>
            </div>
            <div class="contact-content" style="padding: 30px">
              <img
                src="../assets/images/我们的愿景@2x.png"
                style="margin-bottom: 30px"
              >
              <img
                src="../assets/images/我们的使命@2x.png"
                style="margin-bottom: 30px"
              >
              <img src="../assets/images/我们的价值观@2x.png">
            </div>
          </div>
        </div>

        <div class="bodyer-box">
          <div class="zhaopin">
            <div class="zhaopin-title">
              在招职位
              <div>Recruitment Position</div>
            </div>
            <div class="zhaopin-content">
              <div v-for="(item, index) in zhaoPinList" :key="index" class="zhaopin-info" @click="open(item)">
                <div class="zhaopin-name">
                  {{ item.title }}
                </div>
                <div class="zhaopin-pos">
                  {{ item.workArea }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <el-dialog v-if="zhaoPin" :title="'招聘岗位 '+zhaoPin.title" width="80%" :visible.sync="zhaoPinDialogVisible">
      <el-form label-width="80px">
        <el-form-item label="工作地点">
          {{ zhaoPin?zhaoPin.workArea:'' }}
        </el-form-item>
        <el-form-item label="岗位要求">
          <div v-if="zhaoPin" v-html="zhaoPin.job" />
        </el-form-item>
        <el-form-item label="技能要求">
          <div v-if="zhaoPin" v-html="zhaoPin.skill" />
        </el-form-item>
        <el-form-item label="其它要求">
          <div v-if="zhaoPin" v-html="zhaoPin.other" />
        </el-form-item>
        <el-form-item label="招聘人数">
          {{ zhaoPin?zhaoPin.num>0?zhaoPin.num:'人数不限':'' }}
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>

import { list } from '@/api/zhaopin'

export default {
  data() {
    return {
      zhaoPinList: [],
      zhaoPinDialogVisible: false,
      zhaoPin: null
    }
  },
  created() {
    this.getZhaoPinList()
  },
  methods: {
    getZhaoPinList() {
      list({}).then(response => {
        this.zhaoPinList = response.data.list
      })
    },
    open(item) {
      this.zhaoPin = item
      this.zhaoPinDialogVisible = true
    }
  }
}
</script>

<style lang="scss" scoped>
  .bodyer {
    padding-top: 70px;

    .bodyer-box {
      padding: 0 80px;
      margin-bottom: 120px;

      .contact {
        img {
          width: 100%;
          height: auto;
        }

        .contact-title {
          font-size: 36px;
          font-weight: bold;
          color: #17254c;
          margin-bottom: 45px;
          border-bottom: solid 1px #efeff1;

          div {
            font-size: 24px;
            font-weight: 400;
            color: #17254c;
            opacity: 0.2;
          }
        }

        .contact-content {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          align-content: flex-start;
          align-items: flex-start;

          .contact-img {
            width: 150px;
            margin-right: 26px;
            img {
              width: 100%;
              height: auto;
            }
          }

          .contact-info {
            flex: 1;

            p {
              font-weight: 400;
              color: #566283;
              text-indent: 2em;
              line-height: 25px;
              margin-bottom: 10px;
            }
          }
        }
      }

      .zhaopin {
        .zhaopin-title {
          font-size: 36px;
          font-weight: bold;
          color: #17254c;
          margin-bottom: 45px;
          border-bottom: solid 1px #efeff1;

          div {
            font-size: 24px;
            font-weight: 400;
            color: #17254c;
            opacity: 0.2;
          }
        }

        .zhaopin-content {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          align-content: flex-start;
          align-items: flex-start;
          border-left: solid 1px #eee;

          .zhaopin-info {
            flex: 0 0 25%;
            text-align: center;
            padding: 50px 0;
            cursor: pointer;
            border-top: solid 1px #eee;
            border-right: solid 1px #eee;
            border-bottom: solid 1px #eee;
            background-color: #FCFCFC;

            .zhaopin-name {
              font-size: 18px;
              color: #2d3753;
              font-weight: bold;
              line-height: 30px;
            }

            .zhaopin-pos {
              font-size: 16px;
              line-height: 15px;
              color: #bec3d2;
            }
          }
        }
      }
    }
  }
</style>
